import { useState, useEffect } from "react";
import loader from "../../methods/loader";
import methodModel from "../../methods/methods";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/global/layout";
import statusModel from "../../models/status.model";
import { Tooltip } from "antd";
import FormControl from "../../components/common/FormControl";
import shared from "./shared";
import FireAPI from "../../api";
import ImageUpload from "../../components/common/ImageUpload";

const AddEdit = () => {
    const table=shared.table
    const { id } = useParams()
    const [images, setImages] = useState({ image: ''});
    const [form, setform] = useState({ id: '', title: '',category:'',description:'',status: 'active' })
    const history = useNavigate()
    const [submitted, setSubmitted] = useState(false)
    const formValidation = [
        { key: 'status', required: true },
        { key: 'category', required: true },
    ]

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid) return
        let method = 'post'
        let value = {
            ...form,
            ...images
        }
        if (value.id) {
            method = 'put'
        } else {
            delete value.id
        }

        loader(true)
        FireAPI.allApi({payload:value,table,method}).then(res => {
            if (res.success) {
                // ToastsStore.success(res.message)
                history(`/${shared.url}`)
            }
            loader(false)
        })
    }

    useEffect(() => {
        if (id) {
            loader(true)
            FireAPI.getSingle({payload:{id},table}).then(res => {
                if (res.success) {
                    let value = res.data
                    let payload = form

                    Object.keys(payload).map(itm => {
                        payload[itm] = value[itm]
                    })

                    setform({
                        ...payload
                    })

                    let img=images
                    Object.keys(img).map(itm => {
                        img[itm] = value[itm]
                    })
                    setImages({...img})

                }
                loader(false)
            })
        }

    }, [id])

    const imageResult = (e, key) => {
        images[key] = e.value
        setImages(images)
    }

    const getError = (key) => {
        return submitted?methodModel.getError(key, form, formValidation)?.message:''
    }

    const [categories,setCategory]=useState([])

    const getCategories=()=>{
        FireAPI.getAll({payload:{status:'active',type:'blog'},table:'categories'}).then(res=>{
            if(res.success){
                setCategory(res.data)
            }
        })
    }

    useEffect(()=>{
        getCategories()
    },[])

    return <>
        <Layout>
            <form onSubmit={handleSubmit}>
                <div className="pprofile1">


                    <div className='flex items-center mb-8'>
                        <Tooltip placement="top" title="Back">

                            <Link to={`/${shared.url}`} className="!px-4  py-2 flex items-center justify-center  rounded-lg shadow-btn hover:bg-[#F3F2F5] border transition-all  mr-3"><i className='fa fa-angle-left text-lg'></i></Link>
                        </Tooltip>
                        <div>
                            <h3 className="text-2xl font-semibold text-[#111827]">
                                {form && form.id ? 'Edit' : 'Add'}  {shared.title}
                            </h3>
                            <p class="text-sm font-normal text-[#75757A]">Here you can see all about your {shared.title}</p>
                        </div>
                    </div>



                    <h3 className="ViewUser mb-3"></h3>
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <FormControl
                                type="text"
                                name="title"
                                label="Title"
                                value={form.title}
                                onChange={e => setform({ ...form, title: e })}
                                required
                            />
                        </div>

                        <div className="col-md-6 mb-3">
                        <FormControl
                                type="select"
                                name="status"
                                label="Status"
                                displayValue="name"
                                placeholder="Select Status"
                                value={form.status}
                                onChange={e => setform({ ...form, status: e })}
                                options={statusModel.list}
                                required
                                error={getError('status')}
                            />
                        </div>

                        <div className="col-md-6 mb-3">
                        <FormControl
                                type="select"
                                name="category"
                                label="Category"
                                displayValue="name"
                                placeholder="Select Category"
                                value={form.category}
                                onChange={e => setform({ ...form, category: e })}
                                options={categories}
                                theme="search"
                                required
                                error={getError('category')}
                            />
                        </div>

                        <div className="col-md-6 mb-3">
                            <FormControl
                                type="editor"
                                name="description"
                                label="Description"
                                value={form.description}
                                onChange={e => setform({ ...form, description: e })}
                                required
                            />
                        </div>

                        <div className="col-span-12 md:col-span-6">
                                <label className='lablefontcls'>Image</label><br></br>
                                <ImageUpload model={shared.table} result={e => imageResult(e, 'image')} value={images.image || form.image} />
                            </div>
                    </div>
                    <div className="text-right">

                        <button type="submit" className="text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">Save</button>
                    </div>
                </div>


            </form>
        </Layout>
    </>
}

export default AddEdit