import { useEffect, useState } from "react"
import { PiFileCsv } from "react-icons/pi"
import FireAPI from "../../api"

const ExportButton=({table})=>{
    const [scrapData, setScrapData] = useState([])
    const [loading, setLoader] = useState(false)
    const exportfun=()=>{
        writeFile()
    }


    const getData=async()=>{
        setLoader(true)
        FireAPI.getAll({table:table,payload:{page:1,count:1000}}).then(res=>{
            setLoader(false)
            if(res.success){
                setScrapData(res.data.map(itm=>{
                    return {
                        ...itm,
                        scrapId:itm.id,
                        accessToken:'',
                    }
                }))
            }
        })
    }

    useEffect(()=>{
        getData()
    },[])

    const writeFile=()=>{
        const link = document.createElement("a");
        let jsondata={
            table:table,
            data:scrapData
        }

        const content = JSON.stringify(jsondata);
        const file = new Blob([content], { type: 'text/plain' });
        link.href = URL.createObjectURL(file);
        let datetime=new Date().toISOString()
        link.download = `mohitk-art-${table}-${datetime}.backup`;
        link.click();
        URL.revokeObjectURL(link.href);
    }
    
    return <>
    <button disabled={loading} className="!px-2.5 text-[#3C3E49] text-sm font-normal py-2.5 flex items-center justify-center gap-2 bg-[#fff] rounded-lg shadow-btn hover:bg-[#F3F2F5] border border-[#D0D5DD] transition-all focus:ring-2 ring-[#F1F2F3] disabled:bg-[#F3F2F5] disabled:cursor-not-allowed" onClick={() => exportfun()}>
                        <PiFileCsv className="text-typo text-xl" /> {loading?'Loading':'Export CSV'}
                    </button>
    </>

}

export default ExportButton