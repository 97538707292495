import React, { useEffect, useState } from 'react';
import ApiClient from '../../methods/api/apiClient';
import './style.scss';
import loader from '../../methods/loader';
import Html from './html';
import {  useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import environment from '../../environment';
import crendentialModel from '../../models/credential.model';
import FireAPI from '../../api';
import methodModel from '../../methods/methods';

const Users = (p) => {
  const table='users'
  const user = crendentialModel.getUser();
  const { role } = useParams();
  const searchState = {data:''};
  const [filters, setFilter] = useState({
    page: 1,
    count: 50,
    search: '',
    role: '',
    isDeleted: false,
  });
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loaging, setLoader] = useState(true);
  const [roles, setRoles] = useState([]);
  const history = useNavigate();

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    if (user && user.loggedIn) {
      setFilter({ ...filters, search: searchState.data, });
      getData({ search: searchState.data, page: 1 });
    }
  }, []);


  const getData = (p = {}) => {
    setLoader(true);
    let filter = { ...filters, ...p };
    filter.name=filter.search
    FireAPI.getAll({payload:filter,table:table}).then((res) => {
      if (res.success) {
        setData(res.data);
        setTotal(res?.total||0);
      }
      setLoader(false);
    });
  };

  const clear = () => {
    setFilter({ ...filters, search: '', page: 1 });
    getData({ search: '', page: 1 });
  };

  const filter = (p={}) => {
    setFilter({ ...filters, page: 1,...p });
    getData({ page: 1,...p });
  };

  const getRoles = () => {
    FireAPI.getAll({payload:{status:'active'},table:'roles'}).then((res) => {
      if (res.success) {
        let data=res.data
        setRoles(data);
      }
    });
  };

  const deleteItem = (id) => {
    if (window.confirm('Do you want to delete this')) {
      loader(true);
      FireAPI.delete({payload:{id:id},table:table}).then((res) => {
        if (res.success) {
          // ToastsStore.success(res.message);
          clear();
        }
        loader(false);
      });
    }
  };

  const pageChange = (e) => {
    setFilter({ ...filters, page: e });
    getData({ page: e });
  };

  const ChangeRole = (e) => {
    setFilter({ ...filters, role: e, page: 1 });
    getData({ role: e, page: 1 });
  };
  const ChangeStatus = (e) => {
    setFilter({ ...filters, status: e, page: 1 });
    getData({ status: e, page: 1 });
  };



  const statusChange = (itm) => {
    if(!isAllow('editUsers')||environment.adminRoleId==itm.role){
      return
  }
    let status = 'active';
    if (itm.status == 'active') status = 'deactive';

    if (
      window.confirm(
        `Do you want to ${
          status == 'active' ? 'Activate' : 'Deactivate'
        } this user`
      )
    ) {
      loader(true);
      FireAPI.put({payload:{...itm,status},table:table}).then(res=>{
        if (res.success) {
            getData()
        }
        loader(false)
    })
    }
  };

  const view = (id) => {
    history('/userDetail/' + id);
  };

  const edit = (id) => {
    let url = `/users/edit/${id}`;
    if (role) url = `/users/${role}/edit/${id}`;
    history(url);
  };

  const add = () => {
    let url = `/users/add`;
    if (role) url = `/users/${role}/add`;
    history(url);
  };

  const exportfun = async () => {
    const token = await localStorage.getItem('token');
    const req = await axios({
      method: 'get',
      url: `${environment.api}api/export/user`,
      responseType: 'blob',
      body: { token: token },
    });
    var blob = new Blob([req.data], {
      type: req.headers['content-type'],
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `Users.xlsx`;
    link.click();
  };

  const reset = () => {
    let filter = {
      status: '',
      search: '',
      role: '',
      page: 1,
    };
    setFilter({ ...filters, ...filter });
    getData({ ...filter });
    // dispatch(search_success(''))
  };

  const isAllow = (key = '') => {
    return methodModel.isAllow(key)
  };



  const sorting = (key) => {
    let sorder = 'asc';
    if (filters.key == key) {
      if (filters.sorder == 'asc') {
        sorder = 'desc';
      } else {
        sorder = 'asc';
      }
    }

    let sortBy = `${key} ${sorder}`;
    setFilter({ ...filters, sortBy, key, sorder });
    getData({ sortBy, key, sorder });
  };

  return (
    <>
      <Html
        sorting={sorting}
        exportfun={exportfun}
        isAllow={isAllow}
        reset={reset}
        roles={roles}
        view={view}
        edit={edit}
        add={add}
        ChangeRole={ChangeRole}
        ChangeStatus={ChangeStatus}
        pageChange={pageChange}
        deleteItem={deleteItem}
        filters={filters}
        filter={filter}
        loaging={loaging}
        data={data}
        total={total}
        table={table}
        setFilter={setFilter}
        statusChange={statusChange}
      />
    </>
  );
};

export default Users;
