import Layout from '../../components/global/layout';
import './style.scss';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { BsTrash3 } from 'react-icons/bs';
import { FiEdit3, FiPlus } from 'react-icons/fi';
import SelectDropdown from '../../components/common/SelectDropdown';
import statusModel from "../../models/status.model";
import Table from '../../components/Table';
import datepipeModel from '../../models/datepipemodel';
import environment from '../../environment';
import ExportButton from '../../components/common/Export';


const Html = ({
    clear,
    sorting,
    edit,
    ChangeStatus,
    statusChange,
    pageChange,
    deleteItem,
    filters,
    loaging,
    data,
    isAllow,
    table,
    total = { total }
}) => {

    const columns = [
        {
            key: 'name', name: 'Name', sort: true,
            render: (row) => {
                return <>{row?.name}</>
            }
        },
        {
            key: 'createdAt', name: 'Created At', sort: true,
            render: (row) => {
                return <>
                    {datepipeModel.datetime(row?.createdAt)}
                </>
            }
        },
        {
            key: 'status', name: 'Status',
            render: (row) => {
                return <>
                    <div className='w-32' onClick={() => statusChange(row)}>
                        <Tooltip placement="top" title="Active / Inactive">
                            <span className='bg-[#EEE] text-sm !px-3 h-[30px] flex items-center justify-center border border-[#EBEBEB] text-[#3C3E49A3] !rounded capitalize'>
                                {row.status == 'deactive' ? 'inactive' : 'active'}
                            </span>
                        </Tooltip>
                    </div>
                </>
            }
        },
        {
            key: 'action', name: 'Action',
            render: (itm) => {
                return <>
                    <div className="flex items-center justify-start gap-1.5">

                        {isAllow('editRoles') && environment.adminRoleId != itm.id && environment.userRoleId != itm.id?
                            <Tooltip placement="top" title="Edit">
                                <a className="border cursor-pointer border-[#6956E5] hover:opacity-70 rounded-lg bg-[#6956E514] w-10 h-10 !text-primary flex items-center justify-center text-xl" onClick={e => edit(itm.id)}>
                                    <FiEdit3 />
                                </a>
                            </Tooltip>
                            : <></>}
                        {isAllow('deleteRoles') && environment.adminRoleId != itm.id
                            && environment.userRoleId != itm.id ? <Tooltip placement="top" title="Delete"> <span className='border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl ' onClick={() => deleteItem(itm.id)}>
                                <BsTrash3 />
                            </span> </Tooltip> : <></>}
                    </div>
                </>
            }
        },
    ]


    return (
        <Layout>
            <div className="flex justify-between items-center mb-6">
                <div>
                    <h3 className="text-2xl font-semibold text-[#111827]"> Roles</h3>
                    <p className="text-sm font-normal text-[#75757A]">Here you can see all about your  Roles</p>
                </div>
                <div className="flex items-center gap-2">

                    <ExportButton table={table} />
                    {isAllow('addRoles') ? <Link className="bg-primary leading-10 h-10 flex items-center shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg gap-2" to="/roles/add">
                        <FiPlus className="text-xl text-white" /> Add Role
                    </Link> : <></>}

                    <SelectDropdown
                        id="statusDropdown"
                        displayValue="name"
                        placeholder='All Status'
                        intialValue={filters.status}
                        result={e => { ChangeStatus(e.value) }}
                        options={statusModel.list}
                    />
                    {filters.status ? <>
                        <button
                            className="bg-primary leading-10 h-10 inline-block shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg"
                            onClick={() => clear()}>
                            Reset
                        </button>
                    </> : <></>}


                </div>


            </div>


            {!loaging ? <>
                <Table
                    className='mb-3'
                    data={data}
                    columns={columns}
                    page={filters.page}
                    count={filters.count}
                    total={total}
                    result={(e) => {
                        if (e.event == 'page') pageChange(e.value)
                        if (e.event == 'sort') sorting(e.value)
                    }}
                />

            </> : <></>}

            {
                loaging ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>
            }



        </Layout>
    );
};

export default Html;
