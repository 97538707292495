import './App.css';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './Pages/Home';
import Login from './Pages/Login';
import NotFoundPage from './Pages/NotFoundPage';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/style.css';
import Profile from './Pages/Profile';
import Settings from './Pages/Settings';
import Forgotpassword from './Pages/Forgotpassword';
import Resetpassword from './Pages/Resetpassword';
import Roles from './Pages/Roles';
import AddEditRole from './Pages/Roles/AddEdit';
import Users from './Pages/Users';
import UserDetail from './Pages/Users/Profiledetail';
import AddEditUser from './Pages/Users/AddEditUser';
import Dashboard from './Pages/Dashboard';
import './scss/main.scss';
import Signup from './Pages/Signup';
import Category from './Pages/Categories';
import AddEditCategory from './Pages/Categories/AddEdit';
import ViewCategory from './Pages/Categories/View';
import Blog from './Pages/Blogs';
import AddEditBlog from './Pages/Blogs/AddEdit';
import ViewBlog from './Pages/Blogs/View';
import Study from './Pages/Study';
import AddEditStudy from './Pages/Study/AddEdit';
import ViewStudy from './Pages/Study/View';
import Code from './Pages/Code';
import AddEditCode from './Pages/Code/AddEdit';
import ViewCode from './Pages/Code/View';
import Comment from './Pages/Comment';
import ViewComment from './Pages/Comment/View';

function App() {

  const routes=[
    {url:'',element:<Home />},
    {url:'/login',element:<Login />},
    {url:'/signup',element:<Signup />},
    {url:'/dashboard',element:<Dashboard />},
    {url:'*',element:<NotFoundPage />},
    {url:'/profile',element:<Profile />},
    {url:'/profile/:tab',element:<Settings />},
    {url:'/forgotpassword',element:<Forgotpassword />},
    {url:'/resetpassword',element:<Resetpassword />},
    {url:'/roles',element:<Roles />},
    {url:'/roles/add',element:<AddEditRole />},
    {url:'/roles/edit/:id',element:<AddEditRole />},
    {url:'/users/add',element:<AddEditUser />},
    {url:'/users',element:<Users />},
    {url:'/users/edit/:id',element:<AddEditUser />},
    {url:'/userDetail/:id',element:<UserDetail />},
    {url:'/category',element:<Category />},
    {url:'/category/edit/:id',element:<AddEditCategory />},
    {url:'/category/add',element:<AddEditCategory />},
    {url:'/category/detail/:id',element:<ViewCategory />},
    {url:'/blog',element:<Blog />},
    {url:'/blog/edit/:id',element:<AddEditBlog />},
    {url:'/blog/add',element:<AddEditBlog />},
    {url:'/blog/detail/:id',element:<ViewBlog />},
    {url:'/study',element:<Study />},
    {url:'/study/edit/:id',element:<AddEditStudy />},
    {url:'/study/add',element:<AddEditStudy />},
    {url:'/study/detail/:id',element:<ViewStudy />},
    {url:'/code',element:<Code />},
    {url:'/code/edit/:id',element:<AddEditCode />},
    {url:'/code/add',element:<AddEditCode />},
    {url:'/code/detail/:id',element:<ViewCode />},
    {url:'/comment',element:<Comment />},
    {url:'/comment/detail/:id',element:<ViewComment />},
  ]

  return (
    <>
    <Router>
      <Routes>
        {routes.map(itm=>{
          return <Route path={itm.url} element={itm.element} />
        })}
      </Routes>
    </Router>
     <div id="loader" className="loaderDiv d-none">
            <div>
                <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
            </div>
        </div>
    </>
    
  );
}

export default App;
