import Layout from "../../components/global/layout"

const Dashboard=()=>{
    return <>
    <Layout>
        dashboard
    </Layout>
    </>
}

export default Dashboard