const shared={
    table:'categories',
    check:'Category',
    url:'category',
    title:'Category',
    searchBy:'name',
    type:[
        {name:'Blog',id:'blog'},
        {name:'Code',id:'code'},
    ]
}
export default shared
