import React, { useState, useEffect } from 'react';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import './style.scss';
import { userType } from '../../../models/type.model';
import Html from './Html';
import { useNavigate } from 'react-router-dom';
import formModel from '../../../models/form.model';
import crendentialModel from '../../../models/credential.model';
import FireAPI from '../../../api';

const EditProfile = () => {
  const user:any = crendentialModel.getUser()
  const [data, setData] = useState('');
  const [form, setForm]:any = useState(userType);
  const history=useNavigate()
  const [submitted, setSubmitted] = useState(false)
  const table='users'
  const gallaryData = () => {
    loader(true)
    FireAPI.getSingle({payload:{id:user.id},table:table}).then((res:any)=>{
      if (res.success) {
        let payload = form
        let value=res.data
        Object.keys(payload).map(itm => {
            payload[itm] = value[itm]
        })
        setForm({
            ...payload
        })
        setData(res.data)
      }
      loader(false)
    })
  };

  const getError = (key:any) => {
    return formModel.getError('profileForm',key)
  }

  const handleSubmit = (e:any) => {
    e.preventDefault();
    setSubmitted(true)
    let invalid = formModel.getFormError('profileForm')
    if (invalid) return

    let value = { name: form.name, phone: form.phone, image: form.image||'', id: form.id }
    if(form?.phone<10){
      return
    }

    loader(true)
    FireAPI.put({payload:value,table:table}).then((res:any)=>{
      console.log("res",res)
      if (res.success) {
        let uUser = { ...user, ...value }
        crendentialModel.setUser(uUser)
        history("/profile")
        // ToastsStore.success(res.message)
      }
      loader(false)
    })
  };

  const uploadImage = (e:any) => {
    setForm({ ...form, baseImg: e.target.value })
    let files = e.target.files
    let file = files.item(0)
    loader(true)
    ApiClient.postFormData('api/upload/image?modelName=users', { file: file, modelName: 'users' }).then(res => {
      if (res.fileName) {
        let image = res.fileName
        setForm({ ...form, image: image, baseImg: '' })
      } else {
        setForm({ ...form, baseImg: '' })
      }
      loader(false)
    })
  }

  useEffect(
    () => {
      if (user) {
        gallaryData();
        let forms:any=document.forms
        let field=forms['profileForm'].elements['name']
        console.log("field",field.minLength)
      }
    },[]);

  return (
    <>
     <Html
     handleSubmit={handleSubmit}
     setForm={setForm}
     form={form}
     uploadImage={uploadImage}
     getError={getError}
     submitted={submitted}
     />
    </>
  );
};

export default EditProfile;
