import React, { useEffect, useState } from 'react';
import './style.scss';
import loader from '../../methods/loader';
import Html from './html';
import { useNavigate } from 'react-router-dom';
import crendentialModel from '../../models/credential.model';
import methodModel from '../../methods/methods';
import FireAPI from '../../api';
import shared from './shared';

const Comment = (p) => {
    const table=shared.table
    const user = crendentialModel.getUser()
    const searchState = {data:''}
    const [filters, setFilter] = useState({ page: 1, count: 50, search: '' })
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [loaging, setLoader] = useState(true)
    const history = useNavigate()

    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: searchState.data })
            getData({ search: searchState.data, page: 1 })
        }
    }, [])

    const sorting = (key) => {
        let sorder = 'asc'
        if (filters.key == key) {
            if (filters.sorder == 'asc') {
                sorder = 'desc'
            } else {
                sorder = 'asc'
            }
        }

        let sortBy = `${key} ${sorder}`;
        setFilter({ ...filters, sortBy, key, sorder })
        getData({ sortBy, key, sorder })
    }

    const getData = (p = {}) => {
        setLoader(true)
        let filter = { ...filters, ...p}
        filter[shared.searchBy]=filter.search
        FireAPI.getAll({payload:filter,table:table}).then(res => {
            if (res.success) {
                let data=res.data
                // data.map(itm=>{
                //     let payload={...itm,createdAt:new Date(itm.date).getTime(),updatedAt:new Date(itm.date).getTime(),status:'active'}
                //     console.log("payload",payload)
                //     FireAPI.put({payload:payload,table})
                // })
                setData(data)
                setTotal(res.total)
            }
            setLoader(false)
        })
    }


    const clear = () => {
        let f={
            search: '',status:'',category:'', page: 1
        }
        setFilter({ ...filters,  ...f})
        getData({ ...f })
    }

    const deleteItem = (id) => {
        if (window.confirm("Do you want to delete this")) {
            loader(true)
            FireAPI.delete({payload:{id},table}).then(res => {
                if (res.success) {
                    // ToastsStore.success(res.message)
                    clear()
                }
                loader(false)
            })
        }
    }

    const pageChange = (e) => {
        setFilter({ ...filters, page: e })
        getData({ page: e })
    }

    const changestatus = (e) => {
        setFilter({ ...filters, status: e, page: 1 })
        getData({ status: e, page: 1 })
    }


    const statusChange = (itm) => {
        let status = 'active'
        if (itm.status == 'active') status = 'deactive'

        if (window.confirm(`Do you want to ${status == 'active' ? 'Activate' : 'Deactivate'} this`)) {
            loader(true)
            FireAPI.put({payload:{...itm,status},table}).then(res => {
                if (res.success) {
                    getData()
                }
                loader(false)
            })
        }
    }

    const edit = (id) => {
        history(`/${shared.url}/edit/${id}`)
    }

    const view = (id) => {
        let url=`/${shared.url}/detail/${id}`
        console.log("view",url)
        history(url)
    }


    const isAllow = (key = '') => {
        return methodModel.isAllow(key)
      };

      const filter = (p={}) => {
        setFilter({ ...filters, page: 1,...p });
        getData({ page: 1,...p });
      };

    return <><Html
        edit={edit}
        view={view}
        clear={clear}
        sorting={sorting}
        isAllow={isAllow}
        pageChange={pageChange}
        deleteItem={deleteItem}
        filters={filters}
        filter={filter}
        setFilter={setFilter}
        loaging={loaging}
        data={data}
        table={table}
        total={total}
        statusChange={statusChange}
        changestatus={changestatus}
    />
    </>;
};

export default Comment;
