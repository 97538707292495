import React, { useEffect, useState } from 'react';
import ApiClient from '../../methods/api/apiClient';
import './style.scss';
import loader from '../../methods/loader';
import Html from './html';
import { useNavigate } from 'react-router-dom';
import environment from '../../environment';
import axios from 'axios';
import crendentialModel from '../../models/credential.model';
import FireAPI from '../../api';
import methodModel from '../../methods/methods';

const Roles = (p) => {
    const table='roles'
    const user = crendentialModel.getUser()
    const searchState = {data:''};
    const [filters, setFilter] = useState({ page: 1, count: 50, search: '', catType: '' })
    const [data, setData] = useState([])
    const [total, setTotal] = useState(0)
    const [loaging, setLoader] = useState(true)
    
    const history = useNavigate()

    useEffect(() => {
        if (user.loggedIn) {
            setFilter({ ...filters, search: searchState.data })
            getData({ search: searchState.data, page: 1 })
        }
    }, [])



    const sorting = (key) => {
        let sorder = 'asc'
        if (filters.key == key) {
            if (filters.sorder == 'asc') {
                sorder = 'desc'
            } else {
                sorder = 'asc'
            }
        }

        let sortBy = `${key} ${sorder}`;
        setFilter({ ...filters, sortBy, key, sorder })
        getData({ sortBy, key, sorder })
    }


    const getData = (p = {}) => {
        setLoader(true)
        let filter = { ...filters, ...p }
        FireAPI.getAll({payload:filter,table:table}).then(res=>{
            setLoader(false)
            if (res.success) {
                setData(res.data.map(itm => {
                    return itm
                }))
                setTotal(res.total)
            }
            
        })
    }


    const clear = () => {
        let f={
            search: '', 
            status:'',
            page: 1
        }
        setFilter({ ...filters, ...f })
        getData(f)
    }

    const deleteItem = (id) => {
        if (window.confirm("Do you want to delete this")) {
            loader(true)

            FireAPI.delete({payload:{id:id},table:table}).then(res=>{
                if (res.success) {
                    // ToastsStore.success(res.message)
                    clear()
                }
                loader(false)
            })
        }
    }

    const pageChange = (e) => {
        setFilter({ ...filters, page: e })
        getData({ page: e })
    }


    const ChangeStatus = (e) => {
        setFilter({ ...filters, status: e, page: 1 })
        getData({ status: e, page: 1 })
    }

    const exportCsv = () => {
        loader(true)
        ApiClient.get('user/csv').then(res => {
            if (res.success) {
                let url = res.path
                let downloadAnchor = document.getElementById("downloadJS")
                downloadAnchor.href = url
                downloadAnchor.click()
            }
            loader(false)
        })
    }


    const statusChange = (itm) => {

        if(!isAllow('editRoles')){
            return
        }
        let status = 'active'

        if (itm.id == environment.adminRoleId||itm.id == environment.userRoleId) {
            return
        }

        if (itm.status == 'active') status = 'deactive'

        if (window.confirm(`Do you want to ${status == 'active' ? 'Activate' : 'Deactivate'} this`)) {
            loader(true)
            FireAPI.put({payload:{...itm,status},table:table}).then(res=>{
                if (res.success) {
                    getData()
                }
                loader(false)
            })
        }
    }

    const view = (id) => {
        history("/roles/" + id)
    }

    const edit = (id) => {
        history(`/roles/edit/${id}`)
    }


    const exportfun = async () => {
        const token = await localStorage.getItem("token");
        const req = await axios({
            method: "get",
            url: `${environment.api}api/export/region`,
            responseType: "blob",
            body: { token: token }
        });
        var blob = new Blob([req.data], {
            type: req.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Roles.xlsx`;
        link.click();
    }


    const isAllow = (key = '') => {
        return methodModel.isAllow(key)
      };


    return <><Html
    clear={clear}
        view={view}
        sorting={sorting}
        isAllow={isAllow}
        edit={edit}
        exportfun={exportfun}
        ChangeStatus={ChangeStatus}
        pageChange={pageChange}
        deleteItem={deleteItem}
        exportCsv={exportCsv}
        filters={filters}
        loaging={loaging}
        data={data}
        total={total}
        table={table}
        statusChange={statusChange}
    />
    </>;
};

export default Roles;
