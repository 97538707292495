import React from 'react';
import Layout from '../../components/global/layout';
import './style.scss';
import { Link } from 'react-router-dom';
import { Tooltip } from "antd";
import { FiEdit3, FiPlus } from 'react-icons/fi';
import { BsTrash3 } from 'react-icons/bs';
import Table from "../../components/Table";
import SelectDropdown from '../../components/common/SelectDropdown';
import statusModel from '../../models/status.model';
import datepipeModel from '../../models/datepipemodel';
import ExportButton from '../../components/common/Export';
import shared from './shared';

const Html = ({
    sorting,
    edit,
    view,
    statusChange,
    pageChange,
    deleteItem,
    clear,
    filters,
    setFilter,
    filter,
    loaging,
    data,
    changestatus,
    isAllow,
    table,
    total = { total }
}) => {

    const columns = [
        {
            key: 'name', name: 'Name', sort: true,
            render: (row) => {
                return <>{row?.name}</>
            }
        },
        
        {
            key: 'type', name: 'Type',
            render: (row) => {
                return <>{row?.type}</>
            }
        },
        {
            key: 'createdAt', name: 'Created At',sort: true,
            render: (row) => {
                return <>
                    {datepipeModel.datetime(row?.createdAt)}
                </>
            }
        },
        {
            key: 'updatedAt', name: 'Updated At',sort: true,
            render: (row) => {
                return <>
                    {datepipeModel.datetime(row?.updatedAt)}
                </>
            }
        },
        {
            key: 'status', name: 'Status',
            render: (itm) => {
                return <>
                    <div onClick={() => statusChange(itm)}>
                        <Tooltip placement="top" title="Active / Inactive">
                            <span className={`${itm.status != 'active' ? 'bg-[#EEE] text-[#3C3E49A3]' : 'bg-[#0fac80] text-white'} text-sm !px-3 h-[30px] flex items-center justify-center border border-[#EBEBEB] !rounded`}>
                                {itm.status != 'active' ? 'Inactive' : 'Active'}
                            </span>
                        </Tooltip>
                    </div>
                </>
            }
        },
        {
            key: 'action', name: 'Action',
            render: (itm) => {
                return <>
                    <div className="flex items-center justify-start gap-1.5">
                    <Tooltip placement="top" title="View">
                                <a className="border cursor-pointer border-[#6956E5] hover:opacity-70 rounded-lg bg-[#6956E514] w-10 h-10 !text-primary flex items-center justify-center text-xl" onClick={e => view(itm.id)}>
                                <span class="material-symbols-outlined">visibility</span>
                                </a>
                            </Tooltip>
                        {isAllow(`edit${shared.check}`) ?
                            <Tooltip placement="top" title="Edit">
                                <a className="border cursor-pointer border-[#6956E5] hover:opacity-70 rounded-lg bg-[#6956E514] w-10 h-10 !text-primary flex items-center justify-center text-xl" onClick={e => edit(itm.id)}>
                                    <FiEdit3 />
                                </a>
                            </Tooltip>
                            : <></>}
                        {isAllow(`delete${shared.check}`) ? <Tooltip placement="top" title="Delete"> <span className='border cursor-pointer !border-[#E9253129] hover:opacity-70 rounded-lg bg-[#FDE9EA] w-10 h-10 text-[#E92531] flex items-center justify-center text-xl ' onClick={() => deleteItem(itm.id)}>
                            <BsTrash3 />
                        </span> </Tooltip> : <></>}
                    </div>
                </>
            }
        },
    ]


    return (
        <Layout>
            <div className="flex justify-between items-center">
                <div>
                    <h3 className="text-2xl font-semibold text-[#111827]"> {shared.title}</h3>
                    <p class="text-sm font-normal text-[#75757A]">Here you can see all about your {shared.title}</p>
                </div>


                <div className="flex gap-2">


                    <ExportButton table={table} />

                    {isAllow(`add${shared.check}`) ?
                        <Link className="bg-primary leading-10 mr-3 h-10 flex items-center shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg gap-2" to={`/${shared.url}/add`}>
                            <FiPlus className="text-xl text-white" />   Add {shared.title}
                        </Link>
                        : <></>}
                </div>


            </div>



            <div className='shadow-box w-full bg-white rounded-lg mt-6'>
                <div className='flex p-4 justify-end'>
                <form class="flex items-center max-w-sm mr-auto" onSubmit={e=>{e.preventDefault();filter()}}>
                        <div class="relative w-full">
                            <input type="text" value={filters.search} onChange={e=>{
                                setFilter({...filters,search:e.target.value})
                            }} id="simple-search" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search branch name..." required />
                        </div>
                        <button type="submit" class="p-2.5 ms-2 text-sm font-medium text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            <svg class="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                            </svg>
                            <span class="sr-only">Search</span>
                        </button>
                    </form>
                    <div className="flex gap-2">
                    <SelectDropdown
                            displayValue="name"
                            placeholder='All Type'
                            intialValue={filters.type}
                            result={e => { filter({type:e.value}) }}
                            options={shared.type}
                        />
                        <SelectDropdown
                            displayValue="name"
                            placeholder='All Status'
                            intialValue={filters.status}
                            result={e => { changestatus(e.value) }}
                            options={statusModel.list}
                        />
                        {filters.status||filters.search||filters.type? <>
                            <button
                                className="bg-primary leading-10 h-10 inline-block shadow-btn px-6 hover:opacity-80 text-sm text-white rounded-lg"
                                onClick={() => clear()}>
                                Reset
                            </button>
                        </> : <></>}


                    </div>
                </div>



                {!loaging ? <>
                    <Table
                        className='mb-3'
                        data={data}
                        columns={columns}
                        page={filters.page}
                        count={filters.count}
                        total={total}
                        result={(e) => {
                            if (e.event == 'page') pageChange(e.value)
                            if (e.event == 'sort') sorting(e.value)
                        }}
                    />

                </> : <></>}

                {
                    loaging ? <div className="text-center py-4">
                        <img src="/assets/img/loader.gif" className="pageLoader" />
                    </div> : <></>
                }

            </div>
        </Layout >
    );
};

export default Html;
