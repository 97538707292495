import {  useNavigate } from "react-router-dom";
import Layout from "../../components/global/layout";
import { useEffect, useState } from "react";
import datepipeModel from "../../models/datepipemodel";
import { useParams } from "react-router-dom";
import shared from "./shared";
import FireAPI from "../../api";
import environment from "../../environment";
import "./style.scss";
const View = () => {
    const table=shared.table
    const [data, setData] = useState({category:''})
    const [category, setCategory] = useState()
    const history = useNavigate()
    const {id}=useParams()

    const getDetail=()=>{
        FireAPI.getSingle({payload:{id},table}).then(res=>{
            if(res.success){
                setData(res.data)
            }
        })
    }

    useEffect(()=>{
        getDetail()
    },[])

    useEffect(()=>{
        if(data.category){
            FireAPI.getSingle({payload:{id:data.category},table:'categories'}).then(res=>{
                if(res.success){
                    setCategory(res.data)
                }
            })
        }
    },[data.category])

    return <>
        <Layout>
            <div className="text-right">
                <div>
                    <a to="/users" onClick={() => history(`/${shared.url}`)}>  <i className="fa fa-arrow-left mr-4 mb-3 " title='Back' aria-hidden="true"></i></a>
                </div>
            </div>
            <div className='bg-white shadow-box rounded-lg w-full p-4 mt-6'>

                <div className="grid grid-cols-12 gap-4">
                    <div className="sideclass col-span-12 md:col-span-12">
                        <h3 className="mt-3 mb-6 py-2 bg-gray-300 px-3">{shared.title} Details</h3>
                        <div className="grid grid-cols-12 gap-4">
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Title</label>
                                <div className='profiledetailscls'>{data?.title || '--'}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Created At</label>
                                <div className='profiledetailscls'>{datepipeModel.datetime(data?.createdAt)}</div>
                            </div>
                            <div className="col-span-12 md:col-span-6">
                                <label className="profileheddingcls">Category</label>
                                <div className='profiledetailscls'>{category?.name || '--'}</div>
                            </div>
                            <div className="col-span-full">
                                <label className="profileheddingcls">View</label>

                                <iframe src={`${environment.frontend}studyview/${id}`} height="200" width="300" className="iframeView"></iframe>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </Layout>
    </>
}

export default View;