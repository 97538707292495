// let host = document.location.host;
let apiUrl ='https://mohitart-api.onrender.com/'
// if(host.includes('localhost')){
//   apiUrl='https://mohitart-api.onrender.com/'
// }

const environment = {
    api: apiUrl,
    map_api_key:'',
    frontend:'https://mohitk-web.web.app/',
    planTypeId:'65ead4e65cfbfd7a03ed102f',
    userRoleId:'rdzCnFcxxT86c1Df4YL4',
    adminRoleId:'x8pj9E8UcZgAeKO0BuNz',
    professionType:'65fa7ad79ef95c639effcf1c',
    productTypeId:'64a7d198fa039f179c0320ca'
  };
  export default environment;
