import firebase from 'firebase';

// var firebaseConfig = {
//     apiKey: 'AIzaSyCzbxPrywBs-awh0IYEpT60Ur1KeJ-E_gc',
//     authDomain: 'fir-node-1bf4a.firebaseapp.com',
//     databaseURL: 'https://fir-node-1bf4a.firebaseio.com',
//     projectId: 'fir-node-1bf4a',
//     storageBucket: 'fir-node-1bf4a.appspot.com',
//     messagingSenderId: '862877386166'
// }

var firebaseConfig1 = {
    apiKey: 'AIzaSyCPgsEuQGDEMu5noCjXoeYx3o8CGKhL9qY',
    authDomain: 'mohitk-art.firebaseapp.com',
    databaseURL: 'https://mohitk-art.firebaseio.com',
    projectId: 'mohitk-art',
    storageBucket: 'mohitk-art.appspot.com',
    messagingSenderId: '720863554288'
};

var firebaseConfig2 = {
    apiKey: 'AIzaSyC4LHkBhYOxh5dV7oT1gkK1dOjwcIRcg80',
    authDomain: 'mohitk-web.firebaseapp.com',
    databaseURL: 'https://mohitk-web.firebaseio.com',
    projectId: 'mohitk-web',
    storageBucket: 'mohitk-web.appspot.com',
    messagingSenderId: '99547249930'
};

let firebaseConfig=firebaseConfig2
let fconfig=sessionStorage.getItem('fconfig')
if(fconfig){
    if(fconfig=='1') firebaseConfig=firebaseConfig1
}else{
    sessionStorage.setItem('fconfig','')
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const firebaseModel = firebase
export { firebaseModel, firebaseConfig }